.page {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  

  .container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

.container.contact-page,
.container.about-page,
.container.portfolio-page {
  
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica', sans-serif;
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

    }

    p {
      font-size: 15px;
      color: #f5f5f5;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

.footer {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #c0c0c0;

  a {
    text-decoration: none;
    color: #c0c0c0;
    
  }

  a:hover {
    color:#ffd700;
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container {
    position: initial;
    height: auto;
    min-height: auto;

    &.contact-page,
    &.about-page,
    &.portfolio-page {
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }

    &.portfolio-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .image-box {
        height: 200px;
        max-width: calc(50% - 10px);
      }
    }
  }}