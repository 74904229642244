.nav-bar {
    background: #000417;
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
        display: block;
        padding: 10px;

        img {
            display: block;
            margin-left: 50px;
            width: 50px;
            height: auto;
            
        }
    }
    
    nav {
        display: block;
        margin: 20px;
        padding: 10px;

        a {
            font-size: 22px;
            padding: 5px 18px;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #ffd700;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        
            &:after {
                content: '';
                font-size: 12px;
                letter-spacing: 2px;
                position: relative;
                right: 35px;
                opacity: 0;
                text-align: center;
                transition: all 0.3s ease-out;
                
            }

            &:first-child {
                &::after{
                    content: 'HOME';
                }
            }


        }

        a.about-link {
            &:after {
                content: 'ABOUT';
                text-align: center;
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }

        a.active {
            svg {
                color: #ffd700; 
            }
        }
        
    }
}